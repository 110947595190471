<template>
  <v-container fill-height fluid class="pa-0 justify-center">
    <!-- currentUser 없을 경우 로그인컴포넌트 띄우는 것 필요함 -->
    <v-card
      class="text-center"
      flat
      color="transparent"
      width="320px"
      v-if="adminValid < 2"
    >
      <v-img
        class="mx-auto"
        width="100px"
        :src="require('@/assets/branding/Signature(ko)_Light.svg')"
      />
      <v-card-title class="justify-center font-weight-bold title pt-4">
        관리자 인증 페이지
      </v-card-title>
      <v-card-subtitle class="pt-2">
        {{ loadingMsg }}
      </v-card-subtitle>
      <v-card-text class="justify-center">
        <v-btn
          elevation="0"
          outlined
          v-if="this.userData.uid"
          @click="checkValid()"
        >
          {{ userData.displayName + " 계정으로 접속" }}
          <v-icon size="18"> mdi-chevron-right </v-icon>
        </v-btn>
        <v-btn elevation="0" outlined v-else @click="$router.push('login')">
          {{ "로그인" }}
          <v-icon size="18"> mdi-chevron-right </v-icon>
        </v-btn>
      </v-card-text>
      <v-card-actions v-if="adminValid === 1" class="justify-center">
        <div>
          <span> 관리자 SMS 추가인증 </span>
          <v-otp-input
            style="width: 240px"
            length="6"
            v-model="code.input"
            :rules="[rule.required, rule.number]"
          />
        </div>
      </v-card-actions>
    </v-card>
    <admin-appbar v-if="adminValid === 2" />
    <router-view v-if="adminValid === 2" />
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import AdminAppbar from "@/components/Appbar/AdminAppbar.vue";
import API from "@/API/auth.js";
export default {
  components: {
    AdminAppbar,
  },
  data() {
    return {
      userData: {},
      adminValid: 0,
      loadingMsg: "메이커 문화 확산을 위해 노력합니다.",
      code: { res: "", input: "" },
      rule: {
        required: (v) => !!v || "필수 항목입니다.",
        number: (v) => /^[0-9|]+$/.test(v) || "숫자만 입력이 가능합니다.",
      },
    };
  },
  async mounted() {
    try {
      await this.checkUser();
    } catch (e) {
      console.log(e);
      this.$toasted.global.error(
        "오류가 발생했습니다. 로그인 정보을 재확인합니다."
      );
      await this.checkUser();
    }
  },
  watch: {
    code: {
      deep: true,
      handler(v) {
        if (this.code.input == this.code.res && this.code.input != "") {
          this.adminValid = 2;
          this.$axios.patch(
            "admin/expUpdate/" + this.userData.uid,
            this.userData
          );
        } else {
          if (this.code.input.length == 6) {
            this.$toasted.global.error("인증번호가 일치하지 않습니다.");
            this.code.input = "";
          }
        }
      },
    },
  },
  methods: {
    async checkUser() {
      window.ChannelIO("shutdown");
      this.loadingMsg = "회원 정보 조회중 ...";
      this.userData = await API.getStoreUserData();
      if (this.userData.uid) this.loadingMsg = "회원 로그인 확인됨";
    },
    async checkValid() {
      this.loadingMsg = "관리자 정보 조회중 ...";
      if (this.userData.level >= 3) {
        this.$toasted.global.error("관리자 권한이 없습니다.");
        return this.$router.push("page");
      } else {
        if (
          this.userData.adminInfo &&
          this.userData.adminInfo.expTime &&
          this.userData.adminInfo.expTime > new Date().getTime()
        ) {
          this.adminValid = 2;
        } else {
          this.adminValid = 1;
          try {
            this.code.res = await this.$axios.patch(
              "notice/RequestVerify",
              this.userData
            );
            this.code.res = this.code.res.data.code;
            this.$toasted.global.notice("인증번호가 카카오 또는 문자로 발송되었습니다.");
          } catch (e) {
            this.$toasted.global.error("인증번호 발송에 실패했습니다.");
            console.log(e);
          }
        }
      }
    },
  },
};
</script>

<style></style>
