<template>
  <div>
    <!-- <v-app-bar
      color="background"
      flat
      app
      height="64"
      v-if="$vuetify.breakpoint.smAndUp"
    > -->
      <!-- 좌측 사이드바 열기 -->
      <!-- <v-btn
        @click="drawer = !drawer"
        class="black--text"
        icon
        v-if="$vuetify.breakpoint.smAndUp"
      >
        <feather type="sidebar" />
      </v-btn>
    </v-app-bar> -->
    <admin-navigation-drawer
      v-if="$vuetify.breakpoint.smAndUp"
      :propDrawer="drawer"
      v-on:update:drawer="
        (v) => {
          drawer = v;
        }
      "
    />
    <admin-bottom-navigation v-else />
  </div>
</template>

<script>
import AdminBottomNavigation from "../Drawer/AdminBottomNavigation.vue";
import AdminNavigationDrawer from "../Drawer/AdminNavigationDrawer.vue";
export default {
  components: { AdminNavigationDrawer, AdminBottomNavigation },
  data: () => ({
    drawer: true,
  }),
  methods: {
    routerPush(routerName, index) {
      if (routerName === "") {
        alert("준비중입니다.");
        this.selectedItem = index;
        return;
      }
      if (this.$router.currentRoute.name !== routerName) {
        this.$router
          .push({ name: routerName })
          .then(() => window.scrollTo(0, 0));
      }
    },
  },
};
</script>

<style></style>
