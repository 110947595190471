import { render, staticRenderFns } from "./AdminNavigationDrawer.vue?vue&type=template&id=791b8646&scoped=true&"
import script from "./AdminNavigationDrawer.vue?vue&type=script&lang=js&"
export * from "./AdminNavigationDrawer.vue?vue&type=script&lang=js&"
import style0 from "./AdminNavigationDrawer.vue?vue&type=style&index=0&id=791b8646&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "791b8646",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAvatar,VCard,VHover,VImg,VList,VListItem,VListItemContent,VMenu,VNavigationDrawer,VTooltip})
