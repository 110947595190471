<template>
  <!-- 어드민 하단바 -->
  <v-bottom-navigation v-model="value" fixed>
    <!-- 이동 탭 -->
    <v-row class="align-center d-flex">
      <v-col
        v-for="item in items"
        :key="item.title"
        class="justify-center d-flex"
        @click="
          routerPush(item.to);
          selectItem = item.to;
        "
        :class="item.to == selectItem ? 'active-class' : ''"
      >
        <feather
          size="18"
          class="mx-auto"
          :type="item.icon"
          v-bind="attrs"
          v-on="on"
          :stroke="item.to == selectItem ? 'var(--v-primary-base)' : 'black'"
        />
      </v-col>
    </v-row>
  </v-bottom-navigation>
</template>

<style scoped>
.active-class {
  border-radius: 0px !important;
}
</style>
<script>
export default {
  data() {
    return {
      selectItem: "",
      items: [
        { title: "회원 관리", icon: "users", to: "Admin-Users" },
        {
          title: "통합 주문 관리",
          icon: "truck",
          to: "Admin-Orders",
        },
      ],
    };
  },
  mounted() {
    this.selectItem = this.$router.currentRoute.name;
  },
  methods: {
    routerPush(routerName) {
      if (routerName === "") {
        return;
      }
      if (this.$router.currentRoute.name !== routerName) {
        this.$router
          .push({ name: routerName })
          .then(() => window.scrollTo(0, 0));
      }
    },
  },
};
</script>

<style></style>
