<template>
  <!-- 어드민 사이드바 -->
  <v-navigation-drawer
    class="surface elevation-0 d-flex"
    app
    hide-overlay
    permanent
    v-model="drawer"
    width="60px"
    height="100vh"
  >
    <!-- 상단 로고 -->
    <div class="d-flex justify-center py-6" @click="backToPage('Home')">
      <v-tooltip right nudge-right="20">
        <template v-slot:activator="{ on, attrs }">
          <!-- 로고이미지 들어가는곳 -->
          <v-hover v-slot="{ hover }" close-delay="200">
            <v-avatar
              size="32"
              v-bind="attrs"
              v-on="on"
              :style="hover ? 'outline: 2px solid var(--v-primary-base)' : ''"
            >
              <v-img
                contain
                :src="require('@/assets/icons/MadeAll_Icon(Light).svg')"
              />
            </v-avatar>
          </v-hover>
        </template>
        <span v-text="'홈페이지 돌아가기'" />
      </v-tooltip>
    </div>

    <!-- 이동 탭 -->
    <div class="justify-center py-2 mb-auto">
      <div
        v-for="item in items"
        :key="item.title"
        class="py-4 d-flex"
        @click="
          routerPush(item.to);
          selectItem = item.to;
        "
        :class="item.to == selectItem ? 'active-class' : ''"
      >
        <v-menu
          v-if="item.to == 'Admin-Orders'"
          :close-on-content-click="true"
          offset-x
          max-width="250"
          right
          nudge-right="24"
          nudge-top="12"
          open-on-hover
          close-delay="200"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-hover v-slot="{ hover }" close-delay="200">
              <feather
                size="18"
                class="mx-auto"
                :type="item.icon"
                v-bind="attrs"
                v-on="on"
                :stroke="
                  item.to == selectItem
                    ? 'var(--v-primary-base)'
                    : hover
                    ? 'var(--v-primary-base)'
                    : 'black'
                "
              />
            </v-hover>
          </template>
          <v-card class="surface">
            <v-list dense class="pa-0 surface">
              <v-list-item
                @click="
                  routerPush(item.to, { type: 'SEARCH' });
                  selectItem = item.to;
                "
                class="py-0"
              >
                <v-list-item-content class="font-weight-regular subtitle-2">
                  주문서 검색
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="
                  routerPush(item.to, { type: 'NEW' });
                  selectItem = item.to;
                "
                class="py-0"
              >
                <v-list-item-content class="font-weight-regular subtitle-2">
                  결제 전 주문
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="
                  routerPush(item.to, { type: 'CONFIRM' });
                  selectItem = item.to;
                "
                class="py-0"
              >
                <v-list-item-content class="font-weight-regular subtitle-2">
                  결제 후 주문
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
        <v-tooltip right nudge-right="20" v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-hover v-slot="{ hover }" close-delay="200">
              <feather
                size="18"
                class="mx-auto"
                :type="item.icon"
                v-bind="attrs"
                v-on="on"
                :stroke="
                  item.to == selectItem
                    ? 'var(--v-primary-base)'
                    : hover
                    ? 'var(--v-primary-base)'
                    : 'black'
                "
              />
            </v-hover>
          </template>
          <span v-text="item.title" />
        </v-tooltip>
      </div>
      <div
        class="py-4 d-flex"
        :class="'개발자 테스트' == selectItem ? 'active-class' : ''"
        v-if="
          $store.getters['auth/user/GET_CLAIMS'] &&
          $store.getters['auth/user/GET_CLAIMS'].level == 0
        "
        @click="
          routerPush('Dev-Test');
          selectItem = '개발자 테스트';
        "
      >
        <v-tooltip right nudge-right="20">
          <template v-slot:activator="{ on, attrs }">
            <v-hover v-slot="{ hover }" close-delay="200">
              <feather
                size="18"
                type="code"
                class="mx-auto"
                v-bind="attrs"
                v-on="on"
                :stroke="
                  'Dev-Test' == selectItem
                    ? 'var(--v-primary-base)'
                    : hover
                    ? 'var(--v-primary-base)'
                    : 'black'
                "
              />
            </v-hover>
          </template>
          <span v-text="'개발자 테스트'" />
        </v-tooltip>
      </div>
    </div>
    <!-- 하단 탭 -->
    <div class="" style="position: fixed; bottom: 10px; width: 100%">
      <!-- 관리자 설정 -->
      <div class="d-flex justify-center py-2" @click="backToPage('MyPage')">
        <v-tooltip right nudge-right="20">
          <template v-slot:activator="{ on, attrs }">
            <!-- 로고이미지 들어가는곳 -->
            <v-hover v-slot="{ hover }" close-delay="200">
              <v-avatar
                size="32"
                v-bind="attrs"
                v-on="on"
                :style="hover ? 'outline: 2px solid var(--v-primary-base)' : ''"
              >
                <v-img
                  contain
                  v-if="$store.getters['auth/user/GET_USER']"
                  :src="$store.getters['auth/user/GET_USER'].photoURL"
                />
              </v-avatar>
            </v-hover>
          </template>
          <span v-text="'관리자 설정'" />
        </v-tooltip>
      </div>
      <!-- 로그아웃 -->
      <div
        class="py-4 d-flex text-end"
        @click="$store.dispatch('auth/user/SET_LOGOUT', 'Home')"
      >
        <v-tooltip right nudge-right="20">
          <template v-slot:activator="{ on, attrs }">
            <v-hover v-slot="{ hover }" close-delay="200">
              <feather
                size="18"
                type="log-out"
                class="mx-auto"
                v-bind="attrs"
                v-on="on"
                :stroke="hover ? 'var(--v-primary-base)' : 'black'"
              />
            </v-hover>
          </template>
          <span v-text="'로그아웃'" />
        </v-tooltip>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<style scoped>
.active-class {
  border-radius: 0px !important;
  box-shadow: 3px 0 0 0 #0090ff inset;
}
</style>
<script>
export default {
  props: {
    propDrawer: Boolean,
  },
  data() {
    return {
      selectItem: "",
      items: [
        { title: "대시보드", icon: "grid", to: "Admin-Dashboard" },
        { title: "회원 관리", icon: "users", to: "Admin-Users" },
        { title: "주문 일지", icon: "list", to: "Admin-Order-List" },
        {
          title: "통합 주문 관리",
          icon: "box",
          to: "Admin-Orders",
        },
        { title: "일정 관리", icon: "calendar", to: "Admin-Calendar" },
        { title: "포트폴리오 관리", icon: "archive", to: "Admin-Portfolio" },
        { title: "소식 관리", icon: "bookmark", to: "Admin-Press" },
        { title: "프린터 관리", icon: "server", to: "Admin-Printer" },
      ],
    };
  },
  mounted() {
    this.selectItem = this.$router.currentRoute.name;
  },
  computed: {
    drawer: {
      get() {
        return this.propDrawer;
      },
      set(newVal) {
        this.$emit("update:drawer", newVal);
      },
    },
  },
  methods: {
    routerPush(routerName, query) {
      if (routerName === "") {
        return;
      }
      if (
        this.$router.currentRoute.name !== routerName ||
        this.$router.currentRoute.query !== query
      ) {
        this.$router
          .push({
            name: routerName,
            query: query || {},
          })
          .then(() => window.scrollTo(0, 0));
      }
    },
    comma(num) {
      return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    backToPage(to) {
      this.routerPush(to);
      const user = this.$store.getters["auth/user/GET_USER"];
      window.ChannelIO(
        "boot",
        {
          pluginKey: "e6541c37-05a3-4119-8e7f-88b9c21a509c",
          memberId: user ? user.uid : undefined,
        },
        function onBoot(error, user) {
          if (error) {
            console.error(error);
          } else {
            window.channelUser = user;
          }
        }
      );
    },
  },
};
</script>

<style></style>
